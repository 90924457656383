import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from 'styles/components/Dots.module.scss';

const Dots = ({ total, selected, onClick, className, light }) => (
  <div className={classes.root}>
    {[...Array(total).keys()].map(i => (
      <div
        onClick={() => onClick(i)}
        className={classNames(
          classes.item,
          { [classes.selected]: i === selected },
          className,
          { [classes.light]: light },
          { [classes.dark]: !light },
        )}
        key={i}
      />
    ))}
  </div>
);

Dots.propTypes = {
  total: PropTypes.number,
  selected: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  light: PropTypes.bool,
};

Dots.defaultProps = {
  total: 3,
  selected: 1,
  className: '',
  light: false,
};

export default Dots;
