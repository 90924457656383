import PropTypes from 'prop-types';
import classNames from 'classnames';

import Spacer from 'components/Spacer';

import classes from 'styles/pages/Home/Moments/OptionsList/Option.module.scss';
import Arrow from 'icons/arrow.svg';

import Details from '../Details';

const Option = ({ select, selected, className: externClassName, children, index, ...props }) => {
  const className = classNames(
    classes.root,
    {
      [classes.selected]: selected,
    },
    externClassName,
  );

  return (
    <div className={className} {...props} onClick={select}>
      <div className={classes.title}>
        {children}

        <Spacer h={48} />

        {selected ? <Arrow /> : <Spacer h={10} />}
      </div>
      {selected && (
        <div className={classes.details}>
          <Details selected={index} />
        </div>
      )}
    </div>
  );
};

Option.propTypes = {
  select: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
};

Option.defaultProps = {
  selected: false,
  className: '',
};

export default Option;
