import PropTypes from 'prop-types';

import Spacer from 'components/Spacer';
import Typography from 'components/Typography';

import classes from 'styles/components/Benefits/Benefit.module.scss';

const Benefit = ({ icon: IconComponent, title, content }) => (
  <div className={classes.root}>
    <Typography variant="subheader1" bold className={classes.heading}>
      <IconComponent className={classes.icon} alt={title} />
      {title}
    </Typography>

    <Spacer v={16} />

    <Typography variant="body2">{content}</Typography>
  </div>
);

Benefit.propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default Benefit;
