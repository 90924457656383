import Content from 'components/Content';
import Typography from 'components/Typography';
import Spacer from 'components/Spacer';

import classes from 'styles/pages/Home/EstatePlanning.module.scss';

const EstatePlanning = () => {
  const points = [
    { title: 'Follow the step by step checklist' },
    { title: 'Instantly receive your state specific documents' },
    { title: 'Review and edit your documents anytime' },
  ];

  return (
    <Content>
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.information}>
            <Typography variant="calltoaction2" upcase>
              Digital Estate Planning
            </Typography>

            <Spacer v={8} />

            <Typography variant="h2" className={classes.titleDesktop}>
              Create your Estate Plan Online in 15 Minutes
            </Typography>
            <Typography variant="subheader1" className={classes.titleMobileTablet}>
              Create your Estate Plan Online in 15 Minutes
            </Typography>

            <Spacer v={8} />

            <ul className={classes.list}>
              {points.map(({ title }) => (
                <li key={title} className={classes.bullet}>
                  <Typography variant="body2" component="span">
                    {title}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
          <img src="/estateplanning.png" alt="" className={classes.image} />
        </div>
      </div>
    </Content>
  );
};

export default EstatePlanning;
