import PropTypes from 'prop-types';

import Content from 'components/Content';
import classes from 'styles/components/Benefits.module.scss';
import Benefit from './Benefit';

const Benefits = ({ options }) => (
  <Content>
    <div className={classes.root}>
      {options.map((o, idx) => (
        <Benefit key={idx} {...o} />
      ))}
    </div>
  </Content>
);

Benefits.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Benefits;
