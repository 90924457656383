import PropTypes from 'prop-types';

import Typography from 'components/Typography';

import classes from 'styles/pages/Home/Moments/OptionsList/OptionsList.module.scss';
import Option from './Option';
import listItems from './options';

const OptionsList = ({ selected, setSelected }) => (
  <div className={classes.root}>
    {listItems.map(({ title }, idx) => (
      <Option selected={selected === idx} select={() => setSelected(idx)} key={title} index={idx}>
        <Typography variant="subheader1">{title}</Typography>
      </Option>
    ))}
  </div>
);

OptionsList.propTypes = {
  selected: PropTypes.number.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default OptionsList;
