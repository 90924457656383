import PropTypes from 'prop-types';

import Typography from 'components/Typography';
import ListItem from 'components/ListItem';

import classes from 'styles/pages/Home/Moments/Details/Details.module.scss';
import listOptions from './options';

const Details = ({ selected }) => {
  const { image, options } = listOptions[selected];

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <img src={image} className={classes.image} alt={options[selected]} />

        <div className={classes.list}>
          {options.map(o => (
            <ListItem key={o}>
              <Typography>{o}</Typography>
            </ListItem>
          ))}
        </div>
      </div>
    </div>
  );
};

Details.propTypes = {
  selected: PropTypes.number.isRequired,
};

export default Details;
