const options = [
  {
    image: '/moments/getting-married.jpg',
    options: [
      'Prenuptial Agreements',
      'Changing your name',
      'Learning about, updating, or creating estate planning documents',
    ],
  },
  {
    image: '/moments/buying-renting.jpg',
    options: [
      'Reviewing contracts and purchase agreements',
      'Preparing the deeds',
      'Attending the closing',
    ],
  },
  {
    image: '/moments/starting-a-family.jpg',
    options: [
      'Guidance with will and estate planning documents',
      'Attending school and administrative hearings',
      'Adoption procedures',
    ],
  },
  {
    image: '/moments/security-theft.jpg',
    options: [
      'Attorney consultations to respond to creditors',
      'Help contacting banks and creditors',
      'Attorney defense for issues caused by identity theft',
    ],
  },
  {
    image: '/moments/sending-kids-to-college.jpg',
    options: [
      'Help with security deposits',
      'Reviewing any lease documents',
      'Help with student loan debt',
    ],
  },
  {
    image: '/moments/caring.jpg',
    options: [
      'Looking over nursing home agreements',
      'Help reviewing estate planning documents',
      'Looking over Medicare/Medicaid documents',
    ],
  },
];

export default options;
