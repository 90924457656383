const options = [
  { title: 'Getting Married' },
  { title: 'Buying, Selling, or Renting a Home' },
  { title: 'Starting a Family' },
  { title: 'Dealing with Identity Theft' },
  { title: 'Sending Kids off to College' },
  { title: 'Caring for Aging Parents' },
];

export default options;
