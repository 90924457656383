import { useRouter } from 'next/router';
import Nav from 'components/Nav';
import Typography from 'components/Typography';
import Content from 'components/Content';
import Spacer from 'components/Spacer';

import SideBySide from 'components/SideBySide';
import Benefits from 'components/Benefits';
import Line from 'components/Line';
import OutlinedBox from 'components/OutlinedBox';
import Hero from 'components/Hero';
import Footer from 'components/Footer';

import { Moments, Testimonials, EstatePlanning } from 'components/pages/Home';

import classes from 'styles/pages/Home/Home.module.scss';

import Planning from 'icons/planning.svg';
import Shield from 'icons/shield.svg';
import Infinity from 'icons/infinity.svg';

import { EMPLOYERS_LINK, REGISTER, FEDERAL_BENEFITS } from 'utils/constants/urls';

const benefits = [
  {
    icon: Shield,
    title: 'Attorneys at your fingertips',
    content:
      'It’s never been easier to find an attorney. We have the largest network available, with over 18,000 professionals across the country. Choose the best fit for you, or work with your own attorney and we’ll reimburse you a portion or all of the attorney’s fees.',
  },
  {
    icon: Infinity,
    title: 'Unlimited initial consultations',
    content:
      'Our plan covers a wide range of different legal matters and there are no restrictions on how often you may utilize your plan. The plan also includes unlimited initial consultations with an attorney, as long as it’s not an excluded matter.',
  },
  {
    icon: Planning,
    title: 'Estate planning',
    content:
      'Choose to create a will online, on your own time, in as little as 15 minutes or visit an attorney. We will help you create and file your estate planning documents.',
  },
];

const goToRegister = () => {
  window.location = REGISTER;
};

const navLinks = [
  { text: 'Why MetLife Legal Plans?', href: '/why-enroll' },
  { text: 'How We Work', href: '#how-we-work' },
  { text: 'Reviews', href: '#reviews' },
  { text: 'About Us', href: '/about-us' },
  { text: 'Diversity', href: '/diversity' },
  { text: 'For Employers', href: EMPLOYERS_LINK, external: true },
  { text: 'For Federal Government', href: FEDERAL_BENEFITS, external: true },
];

const Home = () => {
  const router = useRouter();

  return (
    <>
      <Nav links={navLinks} />
      <main role="main">
        <Hero ctaButtonText="Why Enroll?" ctaOnClick={() => router.push('/why-enroll')}>
          <h1>
            <Typography unsetColor variant="h1">
              Legal Help Whenever You Need It
            </Typography>
          </h1>

          <Spacer v={16} />

          <Typography unsetColor variant="body1">
            Life is filled with moments where you might need legal help. From exciting moments like
            buying a home, to less exciting ones like getting a speeding ticket, we make legal help
            for life’s big moments affordable, accessible, and easy.
          </Typography>
        </Hero>

        <Line />
        <a id="moments" name="moments" />
        <Moments />
        <Line />

        <Spacer v={32} />

        <Content>
          <OutlinedBox onClick={goToRegister}>
            <Typography variant="h2">
              Simply choose a network attorney and make an appointment. We'll take care of the rest.
            </Typography>
          </OutlinedBox>

          <Spacer v={64} />

          <a id="how-we-work" name="how-we-work" />
          <SideBySide>
            <div>
              <Typography variant="h2">
                <Shield className={classes.affordableIcon} />
                Easy and Affordable
              </Typography>

              <Spacer v={32} />

              <Typography>
                Our goal has always been to provide affordable, high quality legal help, and to make
                it easy for you to get that help. We provide access to attorneys for all of the
                matters above, and more, for a low monthly fee with no waiting periods, deductibles,
                copays, or claims forms when using a network attorney for a covered matter. Our
                attorneys will stick with you, from start to finish, no matter how long it takes to
                complete your issue.
              </Typography>
            </div>

            <img src="/stock-image.png" alt="" className={classes.stockImage} />

            {/* <iframe
              title="video"
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/-zzvbfAggNQ?cc_load_policy=1&cc=on&hl=en"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              className={classes.affordableVideo}
              allowFullScreen
            /> */}
          </SideBySide>
        </Content>

        <Spacer v={32} className={classes.affordableVideo} />

        <Line secondary />
        <Benefits options={benefits} />

        <Spacer v={64} />

        <a id="reviews" name="reviews" />

        <EstatePlanning />

        <Line />

        <Testimonials />
        <Spacer v={16} />

        <Spacer v={80} />

        <Content>
          <OutlinedBox onClick={goToRegister}>
            <Typography variant="h2">We'll connect you with an attorney near you.</Typography>
          </OutlinedBox>
        </Content>
      </main>

      <Footer />
    </>
  );
};

export default Home;
