import { useState } from 'react';
import Typography from 'components/Typography';
import Content from 'components/Content';
import Spacer from 'components/Spacer';

import Dots from 'components/Dots';

import classes from 'styles/pages/Home/Testimonials.module.scss';

import QuoteIcon from 'icons/quote.svg';

const testimonials = [
  {
    quote:
      'My spouse and I decided it was time to do our estate planning. Our attorney in San Francisco made the process easy. He listened to us, what we wanted and made great recommendations on how we can best plan for the future. He explained in simple language all the documents that we were signing. We would recommend him for estate planning needs.',
    name: 'Angela Smith',
    plan: 'Plan Member in San Francisco, CA',
  },
  {
    quote:
      'After moving out of an apartment with time left on the lease, the apartment management tried to say we broke the lease, even though we continued to pay rent. My attorney successfully represented us in court, establishing a new precedent definition of “constructive eviction” in New Jersey law. I could not have brought the action myself, and his professionalism and expertise was very impressive. I was extremely fortunate to have been a member of MetLife Legal Plans when this unexpected situation arose.',
    name: 'Michael L.',
    plan: 'Plan Member in New Jersey',
  },
  {
    quote:
      'I received a traffic ticket in the mail (was caught on camera). I have never received a ticket in my life and I am 60 years old. I panicked and called my plan attorney’s office and was given his cell phone number. That right there stunned me. I proceeded to call him, left a message and 10 minutes later, he called back. He took care of the entire deal for me. He is an extremely caring and professional individual. Thank you, you saved my life.',
    name: 'Olivia H.',
    plan: 'Plan Member in Homestead, FL',
  },
];

const Testimonials = () => {
  const [slide, setSlide] = useState(0);

  return (
    <Content>
      <div className={classes.root}>
        <Typography bold variant="subheader1" className={classes.title}>
          Reviews
        </Typography>

        {testimonials.map(
          (testimonial, i) =>
            slide === i && (
              <div key={testimonial.name} className={classes.carousel}>
                <QuoteIcon />
                <Typography variant="quote">{testimonial.quote}</Typography>
                <QuoteIcon className={classes.iconReverse} />

                <Spacer v={32} />

                <Typography variant="subheader1">{testimonial.name}</Typography>

                <Typography variant="subheader2">{testimonial.plan}</Typography>
              </div>
            ),
        )}
      </div>
      <Dots
        selected={slide}
        onClick={i => {
          setSlide(i);
        }}
      />
    </Content>
  );
};

export default Testimonials;
