import { useState } from 'react';

import Content from 'components/Content';
import Typography from 'components/Typography';

import classes from 'styles/pages/Home/Moments.module.scss';
import OptionsList from './OptionsList';
import Details from './Details';

const Moments = () => {
  const [selected, setSelected] = useState(0);

  return (
    <Content>
      <div className={classes.root}>
        <Typography variant="h1" unsetColor>
          Moments We Can Help With
        </Typography>

        <div className={classes.headingSpacer} />

        <div className={classes.information}>
          <OptionsList {...{ selected, setSelected }} />
          <div className={classes.details}>
            <Details selected={selected} />
          </div>
        </div>
      </div>
    </Content>
  );
};

export default Moments;
